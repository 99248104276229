exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-chars-tsx": () => import("./../../../src/pages/all-chars.tsx" /* webpackChunkName: "component---src-pages-all-chars-tsx" */),
  "component---src-pages-ascii-tsx": () => import("./../../../src/pages/ascii.tsx" /* webpackChunkName: "component---src-pages-ascii-tsx" */),
  "component---src-pages-hiragana-katakana-tsx": () => import("./../../../src/pages/hiragana-katakana.tsx" /* webpackChunkName: "component---src-pages-hiragana-katakana-tsx" */),
  "component---src-pages-index-sf-tsx": () => import("./../../../src/pages/index-sf.tsx" /* webpackChunkName: "component---src-pages-index-sf-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jtc-all-chars-tsx": () => import("./../../../src/pages/jtc/all-chars.tsx" /* webpackChunkName: "component---src-pages-jtc-all-chars-tsx" */),
  "component---src-pages-jtc-ascii-tsx": () => import("./../../../src/pages/jtc/ascii.tsx" /* webpackChunkName: "component---src-pages-jtc-ascii-tsx" */),
  "component---src-pages-jtc-hiragana-katakana-tsx": () => import("./../../../src/pages/jtc/hiragana-katakana.tsx" /* webpackChunkName: "component---src-pages-jtc-hiragana-katakana-tsx" */),
  "component---src-pages-jtc-index-tsx": () => import("./../../../src/pages/jtc/index.tsx" /* webpackChunkName: "component---src-pages-jtc-index-tsx" */),
  "component---src-pages-tips-tsx": () => import("./../../../src/pages/tips.tsx" /* webpackChunkName: "component---src-pages-tips-tsx" */)
}

